import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import Spinner from 'react-spinkit';

import api from 'services/api';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {colors} from 'styles';

import {
  Container,
  ModalContent,
  InputContainer,
  InputTable,
  InputLabel,
  ButtonsContainer,
  ButtonRight,
  ButtonTitleRight,
} from './styles';

const FAST_TIPOIDENTIFICACAO = {
  UH: 1,
};

export const ModalAskUh = ({isOpen, onCancel, onConfirm, onError}) => {
  const inputUhRef = useRef();
  const inputLastNameRef = useRef();
  const {t} = useTranslation();
  const [uh, setUh] = useState('');
  const [lastname, setLastname] = useState('');
  const [loading, setLoading] = useState(false);

  const {pdv, mesa, serie} = useSelector(
    reduxState => reduxState.config.config,
  );

  const hasMesa = useMemo(() => !!(mesa && mesa?.codigo), [mesa]);

  useEffect(() => {
    if (!isOpen) {
      setUh('');
      setLastname('');
    } else {
      setTimeout(() => {
        inputUhRef.current?.focus();
      }, 300);
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    if (!loading) {
      setLoading(true);

      const url = `/getcontaidentificada/?nfccode=${uh}&pdv=${pdv?.codigo}&tipoidentificacao=${FAST_TIPOIDENTIFICACAO.UH}&mesa=${mesa?.codigo}&webapp=${true}&serie=${serie}`;

      try {
        const response = await api.get(url);

        const {conta, cartao, contas} = response.data;

        let findGuest = null;
        if (lastname && contas && contas.length > 0) {
          contas.forEach(c => {
            const nameSplit = String(c.NmPessoa).split(' ');
            const lastString = nameSplit[nameSplit.length - 1];
            if (lastString.toUpperCase() === lastname.toUpperCase()) {
              findGuest = c;
            }
          });
        }

        setLoading(false);

        if (findGuest) {
          const card = {codigo: hasMesa ? cartao : 0, tipo: 'C'};

          const account = {
            tipo: 1,
            cartao: uh,
            tipoidentificacao: FAST_TIPOIDENTIFICACAO.UH,
            conta: hasMesa ? conta : uh,
            idconta: findGuest?.IdConta || null,
            contaUh: findGuest?.NrConta || null,
            nome: findGuest?.NmPessoa || null,
            contas: findGuest ? [{...findGuest}] : [],
          };

          console.log(account);

          onConfirm({card, account});
        } else {
          onError(t('cart.guestNotFound'));
        }
      } catch (e) {
        setLoading(false);

        onError(e?.response?.data?.msg || t('error.error'));
      }
    }
  };

  return (
    <Modal
      unmountOnClose
      fade
      centered
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        onCancel();
      }}
      backdrop="static">
      <ModalHeader
        toggle={() => {
          if (!loading) {
            onCancel();
          }
        }}>
        {t('cart.account')}
      </ModalHeader>
      <ModalBody>
        <Container>
          <ModalContent>
            <InputLabel>{t('cart.enterUh')}</InputLabel>
            <InputContainer>
              <InputTable
                innerRef={inputUhRef}
                autoFocus
                autoComplete="off"
                onChange={event => {
                  setUh(String(event.target.value).replace(/\D/g, ''));
                }}
                value={uh}
                placeholder=""
                type="tel"
                name="uh"
                id="uh"
                disabled={loading}
                maxLength={4}
              />
            </InputContainer>

            <InputLabel>{t('cart.enterLastname')}</InputLabel>
            <InputContainer>
              <InputTable
                innerRef={inputLastNameRef}
                autoFocus
                autoComplete="off"
                onChange={event => {
                  setLastname(event.target.value);
                }}
                value={lastname}
                placeholder=""
                type="text"
                name="lastname"
                id="lastname"
                disabled={loading}
                maxLength={30}
              />
            </InputContainer>
          </ModalContent>
        </Container>
      </ModalBody>
      <ModalFooter>
        <ButtonsContainer>
          <ButtonRight
            $showLoading={loading}
            disabled={!uh || !lastname || loading}
            onClick={() => {
              handleConfirm();
            }}>
            {loading ? (
              <Spinner name="ball-beat" color={colors.blue} />
            ) : (
              <ButtonTitleRight>{t('actions.confirm')}</ButtonTitleRight>
            )}
          </ButtonRight>
        </ButtonsContainer>
      </ModalFooter>
    </Modal>
  );
};

ModalAskUh.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
