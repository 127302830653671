import React from 'react';
import PropTypes from 'prop-types';

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {
  Container,
  HeaderContainer,
  HeaderGroup,
  HeaderTitle,
  HeaderValue,
  Title,
} from './styles';

export const HeaderInfo = ({title = ''}) => {
  const {t} = useTranslation();
  const {mesa, cartao, conta} = useSelector(state => state.config.config);

  const renderContent = () => (
    <>
      {mesa && mesa.codigo && mesa.codigo !== '0' ? (
        <HeaderGroup>
          <HeaderTitle>{t('extract.table')}</HeaderTitle>
          <HeaderValue> {mesa.codigo}</HeaderValue>
        </HeaderGroup>
      ) : null}
      {cartao && cartao.codigo && cartao.codigo !== '0' ? (
        <HeaderGroup>
          <HeaderTitle>{t('extract.card')}</HeaderTitle>
          <HeaderValue> {cartao.codigo}</HeaderValue>
        </HeaderGroup>
      ) : null}
      {conta &&
      (conta.conta || conta.contaUh) &&
      (conta.contaUh !== '0' || conta.conta !== '0') ? (
        <HeaderGroup>
          <HeaderTitle>{t('extract.account')}</HeaderTitle>
          <HeaderValue> {conta.contaUh || conta.conta}</HeaderValue>
        </HeaderGroup>
      ) : null}
    </>
  );
  return (
    <Container>
      <HeaderContainer>{renderContent()}</HeaderContainer>
      <Title>{title}</Title>
    </Container>
  );
};

HeaderInfo.propTypes = {
  title: PropTypes.string,
};
