import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  FaShoppingCart,
  FaUtensils,
  FaInfoCircle,
  FaStar,
  FaListAlt,
  FaClock,
} from 'react-icons/fa';
import LogoDsl from 'assets/img/logo_rodape.png';

import {useDispatch, useSelector} from 'react-redux';
import {Creators as ConfigActions} from 'store/ducks/config';

import {Alert} from 'providers/alert';

import Header from 'routes/partials/header';
import Main from 'routes/partials/main';

import {HeaderInfo} from 'components/headerInfo';
import {Weather} from 'components/weather';

import {colors} from 'styles';

import {VERSION} from 'config/version';

import {DESBRAVADOR_FAST} from 'helpers/product';
import {ModalAskUh} from './components/modalAskUh';
import {ModalAskCard} from './components/modalAskCard';

import {
  Container,
  HeaderWave,
  ListView,
  Card,
  CardBody,
  CardButton,
  CardTitle,
  Content,
  Footer,
  WeatherContainer,
  BadgeContainer,
  Badge,
  VersionContainer,
} from './styles';

const Dashboard = () => {
  const [modalUhVisible, setModalUhVisible] = useState(false);
  const [modalCardVisible, setModalCardVisible] = useState(false);

  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const {
    mesa,
    cartao,
    conta,
    dadoshotel,
    ocultarcardapio,
    bloquearpedido,
    hideextrato,
    permiteAptoNaMesa,
    cardOrAccountUpdated,
    atribuirNumeroMesaNoCartao,
    usamonitorapi,
    product,
  } = useSelector(state => state.config.config);
  const pedidos = useSelector(state => state.pedido.itens);

  const hasMesa = useMemo(() => !!(mesa && mesa?.codigo), [mesa]);
  const hasCard = useMemo(() => !!(cartao && cartao?.codigo), [cartao]);
  const hasAccount = useMemo(
    () => !!(conta && (conta?.conta || conta?.contaUh)),
    [conta],
  );

  const shouldAskIsUhOrAccount = useMemo(
    () =>
      hasMesa &&
      !hasCard &&
      !hasAccount &&
      permiteAptoNaMesa &&
      !cardOrAccountUpdated,
    [hasMesa, hasCard, hasAccount, permiteAptoNaMesa, cardOrAccountUpdated],
  );

  const shouldAskAccount = useMemo(
    () =>
      hasMesa &&
      !hasCard &&
      !hasAccount &&
      permiteAptoNaMesa &&
      product === DESBRAVADOR_FAST &&
      !cardOrAccountUpdated,
    [
      hasMesa,
      hasCard,
      hasAccount,
      permiteAptoNaMesa,
      cardOrAccountUpdated,
      product,
    ],
  );

  const shouldAskUh = useMemo(
    () => !hasMesa && !hasCard && !hasAccount && !cardOrAccountUpdated,
    [hasMesa, hasCard, hasAccount, cardOrAccountUpdated],
  );

  const handleConfirmUh = useCallback(
    ({card, account}) => {
      setModalUhVisible(false);

      dispatch(ConfigActions.configUpdateCardAndAccount({card, account}));
    },
    [dispatch],
  );

  const handleConfirmCard = useCallback(
    value => {
      setModalCardVisible(false);

      dispatch(ConfigActions.configUpdateCard(value));
    },
    [dispatch],
  );

  const showAskIsUh = useCallback(() => {
    Alert(
      {
        title: t('cart.questionGuest'),
        text: '',
        icon: 'question',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonText: t('actions.yes'),
        cancelButtonText: t('actions.no'),
      },
      res => {
        if (res.isConfirmed) {
          setModalUhVisible(true);
        } else if (res.dismiss === 'cancel') {
          if (atribuirNumeroMesaNoCartao) {
            const newCard = {
              tipo: 'C',
              codigo: mesa?.codigo,
            };
            handleConfirmCard(newCard);
          } else {
            setModalCardVisible(true);
          }
        }
      },
    );
  }, [t, mesa, atribuirNumeroMesaNoCartao, handleConfirmCard]);

  const handleCancelUh = () => {
    setModalUhVisible(false);

    if (shouldAskAccount) {
      setModalCardVisible(true);
    } else if (shouldAskIsUhOrAccount) {
      showAskIsUh();
    } else if (shouldAskUh) {
      setModalUhVisible(true);
    }
  };

  useEffect(() => {
    if (shouldAskAccount) {
      setModalCardVisible(true);
    } else if (shouldAskIsUhOrAccount) {
      showAskIsUh();
    } else if (shouldAskUh) {
      setModalUhVisible(true);
    }
  }, [shouldAskIsUhOrAccount, shouldAskAccount, shouldAskUh, showAskIsUh]);

  return (
    <>
      <Header />
      <Main hasHeader>
        <Container>
          <ListView>
            <HeaderInfo />
            <Content>
              <HeaderWave>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path
                    fill={colors.white}
                    fillOpacity="1"
                    d="M0,256L60,234.7C120,213,240,171,360,144C480,117,600,107,720,133.3C840,160,960,224,1080,234.7C1200,245,1320,203,1380,181.3L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
                  />
                </svg>
              </HeaderWave>

              {!ocultarcardapio ? (
                <Card>
                  <CardBody>
                    <CardButton to="/products">
                      <FaUtensils color={colors.blue} size={25} />
                      <CardTitle>{t('layout.product')} </CardTitle>
                      {hasAccount ? (
                        <CardTitle>{`(${t('layout.roomService')})`}</CardTitle>
                      ) : (
                        ''
                      )}
                    </CardButton>
                  </CardBody>
                </Card>
              ) : null}

              {!ocultarcardapio && !bloquearpedido ? (
                <Card>
                  <CardBody>
                    <CardButton to="/cart">
                      {pedidos && pedidos.length > 0 ? (
                        <BadgeContainer>
                          <Badge className="animate__animated animate__shakeY">
                            {pedidos.length}
                          </Badge>
                        </BadgeContainer>
                      ) : null}
                      <FaShoppingCart color={colors.blue} size={25} />
                      <CardTitle>{t('layout.cart')}</CardTitle>
                    </CardButton>
                  </CardBody>
                </Card>
              ) : null}

              {!hideextrato ? (
                <Card>
                  <CardBody>
                    <CardButton to="/extract">
                      <FaListAlt color={colors.blue} size={25} />
                      <CardTitle>{t('layout.extract')}</CardTitle>
                    </CardButton>
                  </CardBody>
                </Card>
              ) : null}

              {usamonitorapi ? (
                <Card>
                  <CardBody>
                    <CardButton to="/orders">
                      <FaClock color={colors.blue} size={25} />
                      <CardTitle>{t('layout.trackOrders')}</CardTitle>
                    </CardButton>
                  </CardBody>
                </Card>
              ) : null}

              <Card>
                <CardBody>
                  <CardButton to="/info">
                    <FaInfoCircle color={colors.blue} size={25} />
                    <CardTitle>{t('layout.info')}</CardTitle>
                  </CardButton>
                </CardBody>
              </Card>

              {dadoshotel?.pesquisa && dadoshotel?.pesquisa.length > 0 ? (
                <Card>
                  <CardBody>
                    <CardButton to="/survey">
                      <FaStar color={colors.blue} size={25} />
                      <CardTitle>{t('layout.survey')}</CardTitle>
                    </CardButton>
                  </CardBody>
                </Card>
              ) : null}

              <ModalAskUh
                isOpen={modalUhVisible}
                onCancel={() => {
                  handleCancelUh();
                }}
                onConfirm={values => {
                  handleConfirmUh(values);
                }}
                onError={error => {
                  setModalUhVisible(false);

                  Alert(
                    {
                      title: '',
                      text: error || `${t('cart.guestNotFound')}`,
                      icon: 'warning',
                    },
                    () => {
                      setModalUhVisible(true);
                    },
                  );
                }}
              />

              <ModalAskCard
                isOpen={modalCardVisible}
                onCancel={() => {
                  setModalCardVisible(false);

                  if (shouldAskAccount) {
                    setModalCardVisible(true);
                  } else if (shouldAskIsUhOrAccount) {
                    showAskIsUh();
                  }
                }}
                onConfirm={value => {
                  handleConfirmCard(value);
                }}
                onError={error => {
                  setModalCardVisible(false);

                  Alert(
                    {
                      title: '',
                      text: error || `${t('cart.cardNotFound')}`,
                      icon: 'warning',
                    },
                    () => {
                      setModalCardVisible(true);
                    },
                  );
                }}
              />
            </Content>
            <WeatherContainer>
              <Weather
                lat={dadoshotel?.localizacao?.latitude}
                lon={dadoshotel?.localizacao?.longitude}
                lang={i18n.language}
              />
            </WeatherContainer>
            <Footer>
              <a
                href="https://www.desbravador.com.br"
                target="_blank"
                rel="noreferrer">
                <img src={LogoDsl} alt="logo dsl" />
              </a>

              <VersionContainer className>{VERSION}</VersionContainer>
            </Footer>
          </ListView>
        </Container>
      </Main>
    </>
  );
};

export default Dashboard;
