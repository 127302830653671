import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
// import QrReader from 'react-qr-scanner';
import QrReader from 'react-web-qr-reader';
import Spinner from 'react-spinkit';

import api from 'services/api';

import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {colors} from 'styles';

import {
  Container,
  ModalContent,
  InputContainer,
  InputTable,
  InputLabel,
  ButtonsContainer,
  ButtonRight,
  ButtonTitleRight,
  CameraContainer,
  QRCodeLabel,
  LoadingContainer,
  HeaderTitle,
} from './styles';

const FAST_TIPOIDENTIFICACAO = {
  CARD: 2,
};

export const ModalAskCard = ({isOpen, onCancel, onConfirm, onError}) => {
  const inputRef = useRef();
  const {t} = useTranslation();
  const [text, setText] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);

  const {pdv, mesa, serie} = useSelector(
    reduxState => reduxState.config.config,
  );

  const handleCardValue = value => {
    const card = {
      tipo: 'C',
      codigo: value,
    };

    onConfirm(card);
  };

  const handleQRCodeRead = async qrcode => {
    if (qrcode && !loading) {
      setLoading(true);

      let qrcodeValue = qrcode;
      const checkIfIsUrl = qrcodeValue.split('?cardscan=');
      if (checkIfIsUrl.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        qrcodeValue = checkIfIsUrl[1];
      } else {
        qrcodeValue = String(qrcode).replace(/[\W_]+/g, '');
      }

      const url = `/getcontaidentificada/?nfccode=${qrcodeValue}&pdv=${pdv?.codigo}&tipoidentificacao=${FAST_TIPOIDENTIFICACAO.CARD}&mesa=${mesa?.codigo}&webapp=${true}&serie=${serie}`;

      try {
        const response = await api.get(url);

        const {cartao} = response.data;

        setLoading(false);

        if (cartao) {
          const card = {
            tipo: 'C',
            codigo: cartao,
          };

          onConfirm(card);
        } else {
          onError(t('cart.cardNotFound'));
        }
      } catch (e) {
        setLoading(false);

        onError(e?.response?.data?.msg || t('error.error'));
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setText('');
    } else {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    }
  }, [isOpen]);

  return (
    <Modal
      unmountOnClose
      fade
      centered
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        onCancel();
      }}
      backdrop="static">
      <ModalHeader
        style={{fontSize: '22px'}}
        toggle={() => {
          if (!loading) {
            onCancel();
          }
        }}>
        <HeaderTitle>{t('cart.card')}</HeaderTitle>
      </ModalHeader>
      <ModalBody>
        <Container>
          <ModalContent>
            {!showInput ? (
              <>
                <CameraContainer>
                  {!loading ? (
                    <>
                      <QRCodeLabel>{t('cart.readCardCode')}</QRCodeLabel>
                      <QrReader
                        delay={500}
                        onScan={result => {
                          if (result) {
                            handleQRCodeRead(result?.data);
                          }
                        }}
                        onError={() => {
                          setShowInput(true);
                        }}
                        showViewFinder
                        facingMode="environment"
                        style={{
                          width: '100%',
                          objectFit: 'fill',
                        }}
                      />
                    </>
                  ) : null}

                  {loading ? (
                    <LoadingContainer>
                      <Spinner name="ball-beat" color={colors.blue} />
                    </LoadingContainer>
                  ) : null}
                </CameraContainer>
              </>
            ) : null}
            {showInput ? (
              <>
                <InputLabel>{t('cart.enterCardCode')}</InputLabel>
                <InputContainer>
                  <InputTable
                    innerRef={inputRef}
                    autoFocus
                    autoComplete="off"
                    onChange={event => {
                      setText(String(event.target.value).replace(/\D/g, ''));
                    }}
                    value={text}
                    placeholder=""
                    type="tel"
                    name="card"
                    id="card"
                    disabled={loading}
                    maxLength={4}
                  />
                </InputContainer>
              </>
            ) : null}
          </ModalContent>
        </Container>
      </ModalBody>
      <ModalFooter>
        {showInput ? (
          <ButtonsContainer>
            <ButtonRight
              $showLoading={loading}
              disabled={!text || loading}
              onClick={() => {
                handleCardValue(text);
              }}>
              {loading ? (
                <Spinner name="ball-beat" color={colors.blue} />
              ) : (
                <ButtonTitleRight>{t('actions.confirm')}</ButtonTitleRight>
              )}
            </ButtonRight>
          </ButtonsContainer>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

ModalAskCard.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
