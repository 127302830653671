import {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import ReactGA from 'react-ga4';

export const Tracker = () => {
  const {config, configured} = useSelector(reduxState => reduxState.config);
  const location = useLocation();

  const companyId = useMemo(() => config.dadoshotel?.dados?.id, [config]);

  useEffect(() => {
    if (configured) {
      if (process.env.NODE_ENV !== 'development' && companyId) {
        ReactGA.send({
          hitType: 'pageview',
          page: `/${companyId}${location.pathname}`,
        });
      }
    }
  }, [configured, companyId, location.pathname]);

  return null;
};
