import {createStore, applyMiddleware, compose} from 'redux';

import {persistStore, persistReducer} from 'redux-persist';

import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';

import rootReducer from './ducks';

import {
  ConfigTransform,
  PedidoTransform,
  ProductTransform,
  ExtractTransform,
  PaymentTransform,
  OrderTransform,
} from './transform';

const persistConfig = {
  key: 'ISERVICE',
  storage,
  stateReconciler: hardSet,
  transforms: [
    ConfigTransform,
    PedidoTransform,
    ProductTransform,
    ExtractTransform,
    PaymentTransform,
    OrderTransform,
  ],
};

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

const middlewares = [thunk];

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, enhancer);

export const persistor = persistStore(store);

export default store;
