import api from 'services/api';
import {Creators} from '.';

export const extractRequest =
  ({refresh, pdv, mesa, cartao, conta, serie}) =>
  async dispatch => {
    dispatch(Creators.extractRequest(refresh));

    try {
      let cdmesa = mesa?.codigo;
      let tipo = mesa?.tipo;
      if (cartao && cartao.codigo && cartao.codigo !== '0') {
        cdmesa = cartao?.codigo;
        tipo = 'C';
      }

      let url = `/extrato/?pdv=${pdv.codigo}&cdmesa=${cdmesa}&tipomesa=${tipo}&imp=0&webapp=${true}&serie=${serie}`;

      if (conta && conta.conta && conta.conta !== '0') {
        url = `/extrato/?pdv=${pdv.codigo}&cdmesa=${cdmesa}&tipomesa=${tipo}&tipo=${conta?.tipo}&conta=${conta?.conta}&imp=0&webapp=${true}&serie=${serie}`;
      }

      const response = await api.get(url);

      if (response.data && response.data.extrato) {
        const {extrato} = response.data;

        if (extrato) {
          dispatch(Creators.extractSuccess(extrato));
        } else {
          dispatch(Creators.extractError(true));
        }
      } else {
        dispatch(Creators.extractError(true));
      }
    } catch (e) {
      dispatch(Creators.extractError(true));
    }
  };
