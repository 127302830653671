import React, {useMemo, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Creators as PedidoActions} from 'store/ducks/pedido';
import {Creators as ConfigActions} from 'store/ducks/config';

import ReactGA from 'react-ga4';

import api from 'services/api';

import {Alert} from 'providers/alert';

import PedidoRepository from 'repository/pedido';

import {ProductModal} from 'components/productModal';
import {OverlayLoading} from 'components/overlayLoading';

import {validateExpiryTime} from 'services/validateExpiryTime';
import {validateHourOpen} from 'services/validateHourOpen';

import Header from 'routes/partials/header';
import Main from 'routes/partials/main';
import Footer from 'routes/partials/footer';

import {HeaderInfo} from 'components/headerInfo';
import {CartFooter} from './components/cartFooter';
import {CartItem} from './components/cartItem';
import {ModalAskTable} from './components/modalAskTable';
import {ModalConfirmTable} from './components/modalConfirmTable';

import {Container, ListView, ListEmpty} from './styles';

const Cart = () => {
  const [state, setState] = useState({
    modalProductVisible: false,
    pedidoToEdit: null,
  });
  const [modalAskTableVisible, setModalAskTableVisible] = useState(false);
  const [modalConfirmTableVisible, setModalConfirmTableVisible] =
    useState(false);

  const {sending, itens} = useSelector(reduxState => reduxState.pedido);
  const {
    serie,
    pdv,
    mesa,
    cartao,
    conta,
    estabelecimento,
    solicitarConfirmacaoMesa,
    atribuirNumeroMesaNoCartao,
  } = useSelector(reduxState => reduxState.config.config);

  const dispatch = useDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const totalFormated = useMemo(
    () => PedidoRepository.getTotalFormated(itens),
    [itens],
  );

  const pedidoSendRequest = async options => {
    dispatch(PedidoActions.pedidoSendRequest());

    let orderTable = mesa;
    let orderCard = cartao;
    let orderAccount = conta;

    if (!orderTable) {
      orderTable = {
        codigo: 0,
        tipo: 'M',
      };
    }

    if (!orderCard) {
      orderCard = {
        codigo: 0,
        tipo: 'C',
      };
    }

    if (options && options.table) {
      orderTable = {
        codigo: options.table,
        tipo: 'M',
      };
    }
    if (options && options.card) {
      orderCard = {
        codigo: options.card,
        tipo: 'C',
      };
    }
    if (options && options.account) {
      orderAccount = options.account;
    }

    const data = {
      estabelecimento,
      pdv,
      mesa: orderTable,
      cartao: orderCard,
      destino: orderAccount,
      itens: itens.map(i => ({
        ...i,
        destino: orderAccount,
      })),
    };

    try {
      await api.post(`/pedidos/?webapp=${true}&serie=${serie}`, data);

      Alert({
        title: `${t('order.sent')}`,
        text: `${t('order.sentMessage')}`,
        icon: 'success',
      });

      dispatch(ConfigActions.configResetDate());
      dispatch(PedidoActions.pedidoSendSuccess());

      if (process.env.NODE_ENV !== 'development') {
        ReactGA.event({
          category: 'ORDER',
          action: 'ORDER',
          label: 'ORDER',
        });
      }
      navigate(`/products`, {replace: true});
    } catch (e) {
      const defaultMessageError = t('error.order');
      const error = e?.response.data?.msg || defaultMessageError;

      Alert({
        text: error,
        icon: 'warning',
      });

      dispatch(PedidoActions.pedidoSendError(true));
    }
  };

  const pedidoUpdate = pedido => {
    dispatch(PedidoActions.pedidoUpdate(pedido));
  };

  const handlePedidoUpdate = ({
    product,
    amount,
    observation,
    observations,
    additionals,
  }) => {
    const {pedidoToEdit} = state;
    if (pedidoToEdit) {
      const pedidoUpdated = PedidoRepository.updateOrder(pedidoToEdit, {
        product,
        amount,
        observation,
        observations,
        additionals,
      });

      pedidoUpdate(pedidoUpdated);

      setState(prevState => ({
        ...prevState,
        modalProductVisible: false,
        pedidoToEdit: null,
      }));
    }
  };

  const pedidoRemove = uuid => {
    dispatch(PedidoActions.pedidoRemove(uuid));
  };

  const tableUpdate = table => {
    dispatch(ConfigActions.configUpdateTable(table));
  };

  const sendOrder = options => {
    validateExpiryTime().then(() => {
      validateHourOpen().then(() => {
        pedidoSendRequest(options);
      });
    });
  };

  const showAskTable = () => {
    Alert(
      {
        title: `${t('cart.questionTable', {
          table: mesa?.codigo,
        })}`,
        text: '',
        icon: 'question',
        showCloseButton: false,
        showCancelButton: true,
        confirmButtonText: t('actions.yes'),
        cancelButtonText: t('actions.no'),
      },
      res => {
        if (res.isConfirmed) {
          sendOrder();
        } else if (res.dismiss === 'cancel') {
          setModalConfirmTableVisible(true);
        }
      },
    );
  };

  const handlePressSend = () => {
    if (cartao && !mesa?.codigo && !conta?.conta) {
      if (atribuirNumeroMesaNoCartao) {
        setModalAskTableVisible(true);
      } else {
        sendOrder();
      }
    } else if (mesa?.codigo && solicitarConfirmacaoMesa) {
      showAskTable();
    } else {
      sendOrder();
    }
  };

  const renderModal = () => {
    const {modalProductVisible, pedidoToEdit} = state;
    if (!pedidoToEdit) {
      return null;
    }
    return (
      <ProductModal
        isEdit
        isOpen={modalProductVisible}
        product={pedidoToEdit.produto}
        amount={pedidoToEdit.quantidade}
        observation={pedidoToEdit.observacao}
        observations={pedidoToEdit.observacoes}
        handleClose={() => {
          setState(prevState => ({
            ...prevState,
            modalProductVisible: false,
            pedidoToEdit: null,
          }));
        }}
        onConfirm={({
          product,
          amount,
          observation,
          observations,
          additionals,
        }) => {
          handlePedidoUpdate({
            product,
            amount,
            observation,
            observations,
            additionals,
          });
        }}
      />
    );
  };

  return (
    <>
      <Header showHomeButton />
      <Main hasFooter hasHeader>
        <Container>
          <HeaderInfo />
          <ListView>
            {itens && itens.length > 0 ? (
              itens.map(pedido => (
                <CartItem
                  pedido={pedido}
                  key={pedido.uuid}
                  onPressEdit={() => {
                    setState(prevState => ({
                      ...prevState,
                      modalProductVisible: true,
                      pedidoToEdit: pedido,
                    }));
                  }}
                  onPressRemove={() => {
                    pedidoRemove(pedido.uuid);
                  }}
                />
              ))
            ) : (
              <ListEmpty>
                <p>{t('cart.empty')}</p>
              </ListEmpty>
            )}
            {renderModal()}
          </ListView>

          <CartFooter
            visible={itens && itens.length > 0}
            total={totalFormated}
            onPressSend={() => {
              handlePressSend();
            }}
          />
          <OverlayLoading visible={sending} title={t('cart.sending')} />

          <ModalAskTable
            isOpen={modalAskTableVisible}
            onCancel={() => {
              setModalAskTableVisible(false);
            }}
            onConfirm={value => {
              setModalAskTableVisible(false);

              sendOrder({table: value});
            }}
          />

          <ModalConfirmTable
            isOpen={modalConfirmTableVisible}
            onCancel={() => {
              setModalConfirmTableVisible(false);
            }}
            onConfirm={value => {
              setModalConfirmTableVisible(false);

              tableUpdate({
                tipo: 'M',
                codigo: value,
              });

              sendOrder({table: value});
            }}
            onError={error => {
              setModalConfirmTableVisible(false);

              Alert({
                title: '',
                text: error || `${t('cart.tableNotFound')}`,
                icon: 'warning',
              });
            }}
          />
        </Container>
      </Main>
      <Footer />
    </>
  );
};

Cart.propTypes = {};

export default Cart;
